import {
  Flex,
  Heading,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Select,
  RadioGroup,
  Radio,
  Button,
  Textarea,
  Checkbox,
  Spinner,
  CheckboxGroup,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm, FormProvider, FieldError, useFormContext, SubmitHandler, Controller } from 'react-hook-form'

import { s3ImageURL } from '@miimosa/common'

type Inputs = {
  lastname: string
  firstname: string
  exploitationName: string
  email: string
  mobile: string
  providerNumber: string
  city: string
  collectType: string
  activity: string
  description: string
  siren: string
}

const FormField = ({
  name,
  type,
  isInvalid,
  displayName,
  errors,
}: {
  name: keyof Inputs
  type: 'text' | 'number' | 'tel' | 'email'
  isInvalid: boolean
  displayName: string
  errors?: FieldError
}) => {
  const { register } = useFormContext()

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{displayName}*</FormLabel>
      <Input
        id={name}
        px={2}
        type={type}
        bg="white"
        h="50px"
        {...(type == 'number'
          ? { ...register(name, { required: true, max: 10000000, min: 10 }) }
          : { ...register(name, { required: true, maxLength: 100, minLength: 1 }) })}
      />
      <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
      {(type == 'text' || type == 'tel' || type == 'email') && errors?.type == 'maxLength' && (
        <Text color="red">Ce champ ne doit pas faire plus de 100 caractères.</Text>
      )}
      {(type == 'text' || type == 'tel' || type == 'email') && errors?.type == 'minLength' && (
        <Text color="red">Ce champ doit au minimum faire 1 caractère.</Text>
      )}
      {type == 'number' && errors?.type == 'min' && (
        <Text color="red">Ce champ ne doit pas avoir de valeur inférieure ou égale à 0.</Text>
      )}
      {type == 'number' && errors?.type == 'max' && (
        <Text color="red">Ce champ ne doit pas avoir de valeur supérieur à 10 000 000.</Text>
      )}
    </FormControl>
  )
}

const Form = () => {
  const methods = useForm<Inputs>()

  const [spinner, setSpinner] = useState<boolean>(false)
  const [submittedText, setSubmittedText] = useState({ text: '', color: '', success: false })

  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [checkbox2Checked, setCheckbox2Checked] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSpinner(true)
    const response = await fetch('/nextapi/leads', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        lastname: data.lastname,
        firstname: data.firstname,
        exploitationName: data.exploitationName,
        email: data.email,
        mobile: data.mobile,
        providerNumber: data.providerNumber,
        city: data.city,
        collectType: data.collectType,
        activity: data.activity,
        description: data.description,
        siren: data.siren,
      }),
    })

    if (response.status === 200) {
      setSpinner(false)
      setSubmittedText({
        text: 'Merci pour votre projet ! Un de nos experts vous rappellera dès que possible.',
        color: 'black',
        success: true,
      })
    } else {
      setSpinner(false)
      setSubmittedText({
        text: 'Un problème est survenu, veuillez nous contacter à bonjour@miimosa.com.',
        color: 'red',
        success: false,
      })
    }
  }

  const watchActivity = methods.watch('activity')

  return (
    <FormProvider {...methods}>
      <Flex
        w="full"
        justifyContent="center"
        direction="column"
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        backgroundImage={s3ImageURL('bg-form.png')}
        backgroundRepeat="repeat"
        alignItems="center"
        px={{ base: '10', md: '20' }}
        rowGap={6}
        py={10}
        id="form"
      >
        <Heading size="lg" color="red" textAlign="center">
          Formulaire de candidature
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w="full">
          <FormField
            name={'lastname'}
            displayName="Nom"
            type={'text'}
            isInvalid={methods.formState.errors.lastname?.type == 'required'}
            errors={methods.formState.errors.lastname}
          />
          <FormField
            name={'firstname'}
            displayName="Prénom"
            type={'text'}
            isInvalid={methods.formState.errors.firstname?.type == 'required'}
            errors={methods.formState.errors.firstname}
          />
          <FormField
            name={'exploitationName'}
            displayName="Nom de votre exploitation"
            type={'text'}
            isInvalid={methods.formState.errors.exploitationName?.type == 'required'}
            errors={methods.formState.errors.exploitationName}
          />
          <FormField
            name={'email'}
            displayName="Adresse email professionnelle"
            type={'email'}
            isInvalid={methods.formState.errors.email?.type == 'required'}
            errors={methods.formState.errors.email}
          />
          <FormField
            name={'mobile'}
            displayName="Numéro de téléphone professionnel"
            type={'tel'}
            isInvalid={methods.formState.errors.mobile?.type == 'required'}
            errors={methods.formState.errors.mobile}
          />
          <FormField
            name={'siren'}
            displayName="SIREN"
            type={'text'}
            isInvalid={methods.formState.errors.siren?.type == 'required'}
            errors={methods.formState.errors.siren}
          />
          <FormField
            name={'city'}
            displayName="Commune / Code postal"
            type={'text'}
            isInvalid={methods.formState.errors.city?.type == 'required'}
            errors={methods.formState.errors.city}
          />
          <FormControl isInvalid={methods.formState.errors.collectType?.type == 'required'}>
            <FormLabel htmlFor={'collectType'}>Vous souhaitez réaliser :*</FormLabel>
            <Controller
              name="collectType"
              control={methods.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    placeholder="Séléctionnez une valeur ci-dessous"
                    bg={'white'}
                    h="50px"
                    borderRadius="unset"
                    id="collectType"
                  >
                    <option value="lending">un prêt (pour un montant compris entre 35 000€ et 2M€)</option>
                    <option value="donation">
                      une collecte en don avec contrepartie (pour un montant minimum de 3500€)
                    </option>
                  </Select>
                  <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
                </>
              )}
            />
          </FormControl>
          <FormControl isInvalid={methods.formState.errors.activity?.type == 'required'}>
            <Text mb={4} size="lg">
              Vous êtes*
            </Text>
            <Controller
              name="activity"
              control={methods.control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, name } }) => (
                <Flex direction="column">
                  <RadioGroup onChange={onChange} value={value}>
                    <Flex direction={{ base: 'column', md: 'row' }} columnGap={20} rowGap={4}>
                      <Flex direction={'column'} rowGap={4}>
                        <Radio value="colza" name={name} colorScheme="radio" borderColor="black">
                          Producteur de graines de colza engagé dans la démarche “Lesieur Huiles Engagées”
                        </Radio>
                        <Radio value="tournesol" name={name} colorScheme="radio" borderColor="black">
                          Producteur de graines de tournesol engagé dans la démarche “Lesieur Huiles Engagées”
                        </Radio>
                      </Flex>
                    </Flex>
                  </RadioGroup>
                  <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
                </Flex>
              )}
            />
          </FormControl>
          <FormControl isInvalid={methods.formState.errors.providerNumber?.type == 'required'}>
            <FormLabel htmlFor={'providerNumber'}>Référence de votre organisme stockeur*</FormLabel>
            <Controller
              name="providerNumber"
              control={methods.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    placeholder={
                      watchActivity == 'colza' || watchActivity == 'tournesol'
                        ? 'Séléctionnez une valeur ci-dessous'
                        : 'Veuillez choisir une réponse à la question précédente'
                    }
                    bg={'white'}
                    h="50px"
                    borderRadius="unset"
                    id="providerNumber"
                  >
                    {watchActivity == 'colza' && (
                      <>
                        <option value="SCAEL">SCAEL</option>
                        <option value="Bonneval">Bonneval</option>
                        <option value="Axereal">Axereal</option>
                      </>
                    )}
                    {watchActivity == 'tournesol' && (
                      <>
                        <option value="TDS">Terre du Sud</option>
                        <option value="Euralis">Euralis</option>
                        <option value="Soufflet">Soufflet</option>
                        <option value="Axereal">Axereal</option>
                        <option value="Terrena">Terrena</option>
                        <option value="Acteo">Actéo</option>
                      </>
                    )}
                  </Select>
                  <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
                </>
              )}
            />
          </FormControl>
        </SimpleGrid>
        <FormControl isInvalid={methods.formState.errors.description?.type == 'required'}>
          <FormLabel htmlFor={'description'}>Descriptif du projet*</FormLabel>
          <Textarea
            placeholder="En quelques mots, expliquez-nous le projet pour lequel vous avez un besoin de financement, et quand vous souhaitez percevoir les fonds"
            bg="white"
            h="130px"
            _placeholder={{ color: 'gray' }}
            id="description"
            {...methods.register('description', { required: true, maxLength: 1000, minLength: 1 })}
            w="full"
          />
          <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
          {methods.formState.errors.description?.type == 'maxLength' && (
            <Text color="red">Ce champ ne doit pas faire plus de 1000 caractères.</Text>
          )}
          {methods.formState.errors.description?.type == 'minLength' && (
            <Text color="red">Ce champ doit au minimum faire 1 caractère.</Text>
          )}
        </FormControl>
        <CheckboxGroup>
          <Checkbox
            w="full"
            colorScheme="radio"
            borderColor="black"
            isChecked={checkboxChecked}
            onChange={() => setCheckboxChecked(!checkboxChecked)}
            name="consentment"
          >
            J’accepte de transmettre mes données à MiiMOSA, au titre de cet appel à projets, afin qu’un conseiller
            prenne contact avec moi.
          </Checkbox>
          <Checkbox
            w="full"
            borderColor="black"
            colorScheme="radio"
            isChecked={checkbox2Checked}
            onChange={() => setCheckbox2Checked(!checkbox2Checked)}
            name="cgu"
          >
            J’ai compris que l’obtention de la dotation financière est conditionnée au fait d’être sélectionné(e) par
            Lesieur et à la réussite de ma collecte de financement sur MiiMOSA.
          </Checkbox>
        </CheckboxGroup>
        <Text size="sm">
          Les informations recueillies via ce formulaire sont enregistrées dans un fichier sécurisé par Groupe MiiMOSA
          SAS au capital de 28 843,00 €, RCS de Bobigny n° 803 980 2182, responsable de traitement, aux fins de
          communication et d’échange d’informations suite à votre candidature et dans le cadre de l’exercice de vos
          droits.
        </Text>
        {spinner ? (
          <Flex w="full" justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Flex direction="column" w="full">
            <Text color={submittedText.color}>{submittedText.text}</Text>
            <Button
              type="submit"
              bg="green.800"
              w="fit-content"
              alignSelf={{ base: 'center', md: 'flex-end' }}
              isDisabled={!checkbox2Checked || !checkboxChecked}
            >
              <Heading size="md" color="white">
                Envoyer
              </Heading>
            </Button>
          </Flex>
        )}
      </Flex>
    </FormProvider>
  )
}

export default Form
