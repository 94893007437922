import { Flex, Heading, Text, SimpleGrid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

const Mission = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const iconSize = isMobile ? '2xl' : '5xl'

  return (
    <Flex
      w="full"
      direction="column"
      alignItems="center"
      px={{ base: '10', md: '20' }}
      py={10}
      rowGap={6}
      position="relative"
      id="ambition"
    >
      <Flex
        position="absolute"
        left={{ base: '-70px', md: '-90px' }}
        h={{ base: '200px', md: '240px' }}
        w={{ base: '200px', md: '280px' }}
        transform="rotateY(175deg)"
        {...(!isMobile && { top: 0 })}
        {...(isMobile && { bottom: '100px' })}
      >
        <NextImage
          src={s3ImageURL('colza.png')}
          alt={'colza flower'}
          fill
          style={{ objectFit: 'cover' }}
          sizes="33vw"
        />
      </Flex>
      <Heading color="red" size="lg" textAlign="center">
        L&apos;ambition de Lesieur
      </Heading>
      <Text color="gray" size="lg" px={{ base: 0, md: 40 }} textAlign="center">
        Portée par sa raison d&apos;être &quot;Servir la Terre&quot; et son ambition d’accompagner les Français dans
        leurs transitions alimentaires et environnementales, Lesieur développe une démarche RSE ambitieuse construite
        autour de 4 piliers d’engagements, qui guide l’ensemble de ses initiatives au quotidien, notamment en faveur des
        filières françaises, du climat et de la biodiversité.
      </Text>
      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={{ base: 5, lg: 10 }}>
        <GridItem>
          <Icon name="BetterFood" size={iconSize} h="auto" />
        </GridItem>
        <GridItem>
          <Icon name="LesieurClimat" size={iconSize} h="auto" />
        </GridItem>
        <GridItem>
          <Icon name="Durable" size={iconSize} h="auto" />
        </GridItem>
        <GridItem>
          <Icon name="Inclusive" size={iconSize} h="auto" />
        </GridItem>
      </SimpleGrid>
    </Flex>
  )
}

export default Mission
