import { Flex, Heading, useBreakpointValue, Text } from '@chakra-ui/react'
import { useRef } from 'react'
import Slider from 'react-slick'

import { FrontSearchProjectType } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

import ProjectCard from './ProjectCard'

const Projects = ({ projects }: { projects: FrontSearchProjectType[] }) => {
  const sliderRef = useRef<Slider>(null)
  const itemsPerPage = useBreakpointValue({ base: 1, md: 2, lg: 3 }, { fallback: 'lg' }) as number

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: itemsPerPage,
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <Flex position="relative" w="full" direction="column" py={10} px={{ base: 10, md: 20 }} rowGap={6} id="projects">
      <Heading size="lg" color="red" textAlign="center">
        Les projets soutenus par lesieur
      </Heading>
      {sliderRef && sliderRef.current && (
        <Flex direction="row" alignSelf="flex-end" alignItems="center">
          <Text size="md">Voir plus de projets</Text>
          <Icon
            name="LesieurArrow"
            size="xs"
            transform={'rotate(180deg)'}
            onClick={() => sliderRef.current?.slickPrev()}
            _hover={{ cursor: 'pointer' }}
          />
          <Icon
            name="LesieurArrow"
            size="xs"
            onClick={() => sliderRef.current?.slickNext()}
            _hover={{ cursor: 'pointer' }}
          />
        </Flex>
      )}
      {itemsPerPage >= (projects.length ?? 0) ? (
        <Flex
          direction="row"
          alignItems="center"
          sx={{
            '>div': {
              flex: '1 1 0%',
            },
          }}
        >
          {projects.map((p) => (
            <ProjectCard
              key={p.slug}
              slug={p.slug}
              title={p.title}
              place={p.place}
              state={p.state}
              collectedAmount={p.collect?.collectedAmount || 0}
              description={p.shortDescription}
              progress={p.collect?.progress || 0}
              imageUrl={p.imageFileName}
              collectType={p.collect?.type}
            />
          ))}
        </Flex>
      ) : (
        <Slider {...settings} ref={sliderRef}>
          {projects.map((p) => (
            <Flex key={p.slug} direction="row" py={6}>
              <ProjectCard
                slug={p.slug}
                title={p.title}
                place={p.place}
                state={p.state}
                collectedAmount={p.collect?.collectedAmount || 0}
                description={p.shortDescription}
                progress={p.collect?.progress || 0}
                imageUrl={p.imageFileName}
                collectType={p.collect?.type}
              />
            </Flex>
          ))}
        </Slider>
      )}
    </Flex>
  )
}

export default Projects
