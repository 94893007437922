import { Flex, Text, Link, Heading } from '@chakra-ui/react'
import NextImage from 'next/image'
import { useState } from 'react'

import { Icon } from '@miimosa/design-system'
import { FrontCollectTypeType } from '@miimosa/common'

interface ProjectCardProps {
  slug: string
  imageUrl?: string | null
  title: string
  place: string
  state: string
  collectedAmount: number
  description: string
  progress: number
  collectType?: FrontCollectTypeType
}

const ProjectCard = ({
  slug,
  imageUrl,
  title,
  place,
  state,
  collectedAmount,
  description,
  progress,
  collectType = 'donation',
}: ProjectCardProps) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <Flex w="full" justifyContent="center">
      <Link href={`${process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V2}/projects/${slug}`} isExternal>
        <Flex
          h="612px"
          w={{ base: 'full', md: '361px' }}
          bg="white"
          justifyContent="center"
          direction={'column'}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Flex w="full" h="246px" position="relative" justifyContent="center" alignItems="center">
            {imageUrl && (
              <NextImage
                src={imageUrl}
                alt=""
                fill
                style={{ objectFit: 'cover' }}
                sizes={'(max-width: 600px) 100vw, 33vw'}
              />
            )}
            {hover && (
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                bg="green.800"
                opacity={'60%'}
                zIndex={3}
                h="full"
                w="full"
              >
                <Icon name={'CirclePlus'} size="sm" color="white" />
                <Heading size="sm" color="white">
                  Je découvre le projet
                </Heading>
              </Flex>
            )}
          </Flex>
          <Flex
            direction="column"
            px={6}
            py={8}
            rowGap={3}
            border="1px solid"
            borderTop="0px"
            borderColor="gray"
            borderBottomRadius={10}
          >
            <Heading size="md" noOfLines={2} minH="60px">
              {title}
            </Heading>
            <Flex direction="row" alignItems="center" columnGap={1}>
              <Icon name="PinMap" size="5xs" />
              <Text size="md">{place}</Text>
            </Flex>
            <Flex w="full" h="26px" border="1px solid" borderColor="cream">
              <Flex
                bg={state == 'funded' ? 'blue' : 'orange.800'}
                w="fit-content"
                h="full"
                alignItems="center"
                justifyContent="center"
                px={3}
              >
                <Heading size="sm" whiteSpace="nowrap" color="white">
                  {state == 'funded' ? 'Terminé' : 'En cours'}
                </Heading>
              </Flex>
              <Flex w="full" alignItems="center" justifyContent="center">
                <Text size="md">{collectType == 'donation' ? 'Don avec contrepartie' : 'Prêt Rémunéré'}</Text>
              </Flex>
            </Flex>
            <Flex w="full" h="7px" bg="gray.200" borderRadius={'50'}>
              <Flex h="full" w={`${progress}%`} bg="orange.200" borderLeftRadius={'50'} borderRightRadius={'50'} />
            </Flex>
            <Flex direction="row" w="full" justifyContent="space-between">
              <Text size="sm">
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                }).format(collectedAmount)}{' '}
                collectés
              </Text>
              <Text size="sm" color="green.200" fontWeight={'700'}>
                {progress}%
              </Text>
            </Flex>
            <Text size="md" noOfLines={6} color="gray" minHeight="115px">
              {description}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}

export default ProjectCard
