import {
  Flex,
  Button,
  Link,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'

import CollapsedBloc from './CollapsedBloc'

const HowItWorks = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Flex
      w="full"
      direction="column"
      px={{ base: '10', md: '20' }}
      rowGap={6}
      alignItems="center"
      position="relative"
      py={10}
      id="mission"
    >
      <Heading size="lg" color="red" textAlign="center">
        La démarche « Lesieur Huiles Engagées »
      </Heading>
      <Flex direction="column" w="full">
        <Text color="gray" size="lg">
          Coconstruite avec des acteurs du monde agricole, la démarche « Lesieur Huiles Engagées » s’appuie sur des
          agriculteurs partenaires pour développer et mettre en œuvre une charte de bonnes pratiques agricoles visant à
          cultiver des graines de tournesol et de colza françaises plus durables et plus respectueuses de
          l’environnement.
        </Text>
        <Text color="gray" size="lg">
          <span style={{ color: 'green' }}>« Lesieur Huiles Engagées »</span>, tend à être une démarche qui a l’ambition
          de bénéficier à :
        </Text>
        <UnorderedList>
          <ListItem>
            <Text color="gray" size="lg">
              <span style={{ fontWeight: '700' }}>La planète</span>, en contribuant à la transition environnementale des
              filières françaises des graines de tournesol et de colza via la mise en place de ces pratiques agricoles
              plus durables
            </Text>
          </ListItem>
          <ListItem>
            <Text color="gray" size="lg">
              <span style={{ fontWeight: '700' }}>Le consommateur</span>, en lui proposant une alimentation qui tend à
              être plus responsable et durable
            </Text>
          </ListItem>
          <ListItem>
            <Text color="gray" size="lg">
              <span style={{ fontWeight: '700' }}>L&apos;agriculteur</span>, en l’accompagnant dans ses transitions vers
              plus de durabilité en tenant compte de ses contraintes spécifiques
            </Text>
          </ListItem>
        </UnorderedList>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w="full">
        <Flex direction="column" rowGap={6} alignItems="center" w="full" order={{ base: '2', md: '1' }}>
          <CollapsedBloc
            title={'Comment ça marche ?'}
            data={[
              'Vous êtes :',
              '- producteur de graines de colza ou de tournesol et participant à la démarche “Lesieur Huiles Engagées”',
              '- engagé(e) dans une démarche de transition agricole dans votre exploitation',
            ]}
            bg="white"
            textColor="red"
            w="full"
          />
          <CollapsedBloc
            title={'Pourquoi postuler ?'}
            data={[
              'En étant sélectionné(e) pour lancer votre collecte de financement participatif sur MiiMOSA en don avec contreparties ou en prêt participatif, vous pourrez bénéficier d’un accompagnement et du soutien financier de Lesieur !',
              '- Versement d’une dotation jusqu’à 3500 euros de Lesieur si vous êtes sélectionné(e)',
              '- Financement de votre projet grâce au grand public sur la plateforme MiiMOSA',
              '- Communication renforcée auprès des communautés de Lesieur et MiiMOSA',
              '- Développement de votre notoriété',
            ]}
            bg="white"
            textColor="red"
            w="full"
          />
          <Link href="#form">
            <Button bg="green.800" w="fit-content">
              <Heading color="white" size="md">
                Proposer un projet
              </Heading>
            </Button>
          </Link>
        </Flex>
        <Flex position="relative" h="400px" w="full" order={{ base: '1', md: '2' }}>
          <NextImage src={s3ImageURL('antoine.png')} alt="farmer" fill style={{ objectFit: 'contain' }} sizes="50vw" />
        </Flex>
      </SimpleGrid>
      {!isMobile && (
        <Flex position="absolute" bottom={0} right={0} w="250px" h="200px">
          <NextImage
            src={s3ImageURL('tournesol.png')}
            alt="yellow flower"
            fill
            style={{ objectFit: 'cover' }}
            sizes={'33vw'}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default HowItWorks
