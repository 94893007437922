import { Flex, Heading, Text, useDisclosure, Collapse, BoxProps } from '@chakra-ui/react'
import { useState } from 'react'

const CollapsedBloc = ({
  title,
  data,
  subTextColor = 'black',
  ...props
}: { title: string; data: string[]; subTextColor?: string } & BoxProps) => {
  const { isOpen, onToggle } = useDisclosure()
  const [bgColor, setBgColor] = useState(props.bg)

  const changeBgColorAndSetonToggle = () => {
    setBgColor(bgColor == 'white' ? 'green.800' : 'white')
    onToggle()
  }
  return (
    <Flex bg={bgColor} direction="column" border="solid 1px" borderColor="gray" {...props}>
      <Flex
        direction="row"
        alignItems="center"
        py={4}
        px={4}
        justifyContent="space-between"
        _hover={{ cursor: 'pointer' }}
        onClick={() => changeBgColorAndSetonToggle()}
      >
        <Heading size="sm" color={props.textColor || 'white'}>
          {title}
        </Heading>
        <Heading size="sm" color={props.textColor || 'white'}>
          {isOpen ? '-' : '+'}
        </Heading>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Flex direction="column" py={4} px={4} rowGap={2}>
          {data.map((text, i) => (
            <Text fontSize="12px" color={subTextColor} key={`text_${i}`} whiteSpace="pre-wrap">
              {text}
            </Text>
          ))}
        </Flex>
      </Collapse>
    </Flex>
  )
}

export default CollapsedBloc
