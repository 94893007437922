import { Flex, Heading, Text, Button, useBreakpointValue, Link } from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'

const Hero = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Flex w="full" direction="column">
      <Flex position="relative" w="full" h={{ base: '150px', md: '544px' }}>
        <NextImage
          src={s3ImageURL('texture.png')}
          alt={'hero'}
          fill
          style={{ objectFit: 'cover', backgroundRepeat: 'repeat' }}
          priority
          sizes="100vw"
          quality="100"
        />
        <Flex
          position="absolute"
          right="0"
          w={{ base: '150px', md: '400px', lg: '500px' }}
          h={{ base: '150px', md: '500px' }}
        >
          <NextImage
            src={s3ImageURL('hero-details.png')}
            alt={'hero'}
            fill
            style={{ position: 'absolute', right: 0, objectFit: 'cover' }}
            priority
            sizes="100vw"
            quality="100"
          />
        </Flex>
        <Flex direction="column" zIndex={2} justifyContent="center" rowGap={6} px={20}>
          <Heading size={{ base: 'md', md: 'xl' }} color="green.800" maxW={{ base: '200px', md: '600px', xl: '800px' }}>
            Lesieur s’engage pour la transition environnementale et agroalimentaire
          </Heading>
          {!isMobile && (
            <>
              <Text size="xl" color="green.800" fontWeight="700" maxW={{ base: '400px', lg: '700px' }}>
                Avec MiiMOSA, Lesieur soutient et accompagne les agriculteurs partenaires de la démarche &quot;Lesieur
                Huiles Engagées&quot; pour leur financement de projets en faveur de la transition alimentaire et
                environnementale
              </Text>
              <Link href="#form">
                <Button bg="red" color="white" w="fit-content">
                  <Heading size="md" color="white">
                    Je candidate
                  </Heading>
                </Button>
              </Link>
            </>
          )}
        </Flex>
      </Flex>
      {isMobile && (
        <Flex direction="column" rowGap={6} px={10} alignItems="center" py={10}>
          <Text size="xl" color="green.800" fontWeight="700" textAlign="center">
            en faveur de la transition agricole et alimentaire auprès des agriculteurs partenaires de sa démarche «
            Lesieur Huiles Engagées » en soutenant leurs projets/leurs collectes.
          </Text>
          <Link href="#form">
            <Button bg="red" color="white" w="fit-content">
              <Heading size="md" color="white">
                Je candidate
              </Heading>
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  )
}

export default Hero
