import { Flex, Heading } from '@chakra-ui/react'

import CollapsedBloc from './CollapsedBloc'

const Faq = () => {
  return (
    <Flex w="full" h="auto" bg="white" py={14} px={16} rowGap={8} direction="column" id="faq">
      <Heading size="lg" textAlign="center" color="red">
        Foire aux questions
      </Heading>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} rowGap={8} columnGap={8}>
        <Flex direction="column" w="full" rowGap={8}>
          <CollapsedBloc
            title={'MiiMOSA, c’est quoi ?'}
            data={[
              'MiiMOSA est la plateforme de financement leader en Europe dédiée à la transition agricole et alimentaire. Après avoir accompagné 7 000 projets pour 150 millions d’euros de financement (en France et en Belgique), MiiMOSA a lancé en janvier 2022 le premier fonds de dette dédié à la thématique.',
              'Labellisée BCorp, l’entreprise s’engage à recréer du lien entre des porteurs de projet et l’ensemble de la société, afin que chacun participe à la résolution des enjeux alimentaires, environnementaux, climatiques, sanitaires et énergétiques.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'En savoir plus sur Lesieur'}
            data={[
              'Végétale depuis 1908, Lesieur est une entreprise française qui s’engage en faveur des transitions alimentaires et environnementales. Reconnue pour ses marques iconiques telles que ISIO 4, Cœur de Tournesol, Fleur de Colza, Frial, Olizéa ou encore PUGET, Lesieur met son savoir-faire centenaire au service d’une large gamme de produits alliés culinaires du quotidien. Travaillant étroitement avec des acteurs du monde agricole, Lesieur s’appuie sur la force de son ancrage français et de son collectif de producteurs pour proposer des produits de qualité au plus grand nombre.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'En savoir plus sur la démarche “Lesieur Huiles Engagées”'}
            data={[
              'Coconstruite avec des acteurs du monde agricole, la démarche « Lesieur Huiles Engagées » s’appuie sur des agriculteurs partenaires pour développer et mettre en oeuvre une charte de bonnes pratiques agricoles visant à cultiver des graines de tournesol et de colza françaises plus durables et plus respectueux de l’environnement',
              'Les objectifs de cette charte sont les suivants :',
              "- Développer des cultures plus robustes et plus résilientes pour l’environnement, dans le but d'optimiser les récoltes des agriculteurs, notamment vis à vis des aléas climatiques",
              "- Contribuer à limiter l’utilisation d'intrants et de fertilisants chimiques de synthèse, pour tendre en partie vers une réduction des émissions de carbone.",
              '- Favoriser la biodiversité au sein des exploitations',
              'Cette charte a été conçue pour que les pratiques agricoles soient mieux adaptées aux conditions de cultures liées aux sols ou au climat des agriculteurs partenaires afin que chacun puisse activer les leviers les plus pertinents en fonction de son territoire et de son exploitation. De même, des pratiques distinctes pour chaque culture de graines de colza ou de tournesol ont été développées en tenant compte des spécificités des cultures.',
              'Investie dans une démarche d’amélioration continue, Lesieur échange avec les différents acteurs présents à ses côtés dans la démarche « Lesieur Huiles Engagées » pour faire progresser cette filière vers toujours plus de durabilité.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'Pourquoi Lesieur s’engage ?'}
            data={[
              'Soutenir et développer des filières agricoles françaises plus durables est dans l’ADN de Lesieur depuis toujours. Portée par sa raison d\'être "Servir la Terre" et son ambition d’accompagner les Français dans leurs transitions alimentaires et environnementales, Lesieur développe une démarche RSE ambitieuse construite autour de 4 piliers d’engagements, qui guide l’ensemble de ses initiatives au quotidien, notamment en faveur des filières françaises, du climat et de la biodiversité :',
              '- Ensemble pour le climat et la biodiversité',
              '- Ensemble pour le mieux manger',
              '- Ensemble pour des filières locales et durables',
              '- Ensemble pour construire un projet collectif et inclusif',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'Comment fonctionne l’opération ?'}
            data={[
              '- vous êtes producteur de graines de colza ou de tournesol,',
              '- vous êtes partenaire de la démarche « Lesieur Huiles Engagées » et,',
              '- vous avez un projet de transition agricole qui nécessite un financement',
              'Vous pouvez postuler en remplissant notre formulaire de candidature  pour bénéficier d’un soutien financier. MiiMOSA vous guidera vers la solution de financement participatif adaptée à votre projet et vous accompagnera tout au long de votre collecte.\nPendant l’opération qui se déroulera du 15 avril au 31 novembre 2024, les équipes de MiiMOSA et Lesieur sélectionneront sept projets parmi les candidats qui bénéficieront d’un soutien financier, pouvant atteindre 3 500€ par projet.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
        </Flex>
        <Flex direction="column" w="full" rowGap={8}>
          <CollapsedBloc
            title={'Comment candidater ?'}
            data={[
              'Pour candidater, il suffit de remplir les différentes informations requises du « Formulaire de candidature » plus haut sur cette page.',
              'Vous serez recontacté(e) sous 48h ouvrées par les équipes MiiMOSA.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'Qu’est ce que le financement participatif ?'}
            data={[
              'Le financement participatif (crowdfunding en anglais) est un mode de financement permettant au grand public de soutenir financièrement et collectivement des projets qui le séduisent.',
              'MiiMOSA utilise deux offres de financement participatif : le don avec contrepartie et le prêt rémunéré. Le financement participatif permet également à des acteurs professionnels (investisseurs, entreprises, etc.) de soutenir les projets.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'Quels sont les engagements de Lesieur ?'}
            data={[
              'Lesieur s’engage à :',
              '- verser une dotation financière pouvant aller jusqu’à 3 500€ pour les 7 projets sélectionnés',
              '- apporter de la visibilité aux  agriculteurs sélectionnés auprès de ses intermédiaires (organismes stockeurs), ses consommateurs, et de ses collaborateurs sur son site internet, ses réseaux sociaux et sa communication interne',
              '- renforcer le lien avec les agriculteurs sélectionnés',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'Quels engagements pour le porteur de projets ?'}
            data={[
              'Le porteur de projet s’engage à :',
              '- Faire une collecte en financement participatif',
              '- Etre adhérent à la démarche “Lesieur Huiles Engagées”',
              '- Réaliser le projet de transition pour lequel il est financé avant le 31 novembre 2024',
              '- Accepter d’être identifié(e) comme porteur de projet participant à l’appel à projet',
            ]}
            bg="green.800"
            subTextColor="white"
          />
          <CollapsedBloc
            title={'Que comprend la dotation pour les lauréats ?'}
            data={[
              'La dotation est un soutien financier de Lesieur pouvant aller jusqu’à 3 500€. Cette dotation sera attribuée aux producteurs de graines de colza ou de tournesols qui seront sélectionnés dans le cadre de l’appel à projets.',
            ]}
            bg="green.800"
            subTextColor="white"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Faq
